export default {
    namespaced: true,
    state: {
        userInfo:{}, //用户信息
        personnelInfo:[], //人员信息
        barStatus:'', //底部按钮状态
        visibleCalled:false,
    },
    mutations: {
        /**
         * 用户信息
         * @param state
         * @param userInfo
         * @constructor
         */
        SET_USER_INFO (state,userInfo) {
            state.userInfo = userInfo;
        },
        /**
         * 人员信息
         * @param state
         * @param personnelInfo
         * @constructor
         */
        SET_PERSONNEL_INFO (state,personnelInfo) {
            state.personnelInfo = personnelInfo;
        },
        /**
         * 底部按钮状态
         * @param state
         * @param barStatus
         * @constructor
         */
        SET_BAR_STATUS (state,barStatus) {
            state.barStatus = barStatus;
        },
        /**
         * 呼叫提示框显示
         * @param state
         * @param visibleCalled
         * @constructor
         */
        SET_VISIBLE_CALLED (state,visibleCalled) {
            state.visibleCalled = visibleCalled
        },
    },
}
