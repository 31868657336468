<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>

export default {
    name: 'App',
    data(){
        return {
          screen:false,
        }
    },
    created () {
        //在页面加载时读取localStorage里的状态信息
        if (localStorage.getItem("store") ) {
            this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(localStorage.getItem("store"))))
        }

        //在页面刷新时将vuex里的信息保存到localStorage里
        window.addEventListener("beforeunload",()=>{
          localStorage.setItem("store",JSON.stringify(this.$store.state))
        })
    },
    mounted() {
        // this.device();
        // window.onresize = () => {
        //     return (() => {
        //         this.device();
        //     })()
        // };
        /**
         * 禁止双指放大
         */
        document.documentElement.addEventListener('touchstart', function (event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        }, false);
        /**
         * 禁止双击放大
         * @type {number}
         */
        let lastTouchEnd = 0;
        document.documentElement.addEventListener('touchend', function (event) {
            let now = Date.now();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }
            lastTouchEnd = now;
        }, false);
        document.addEventListener('gesturestart', function(event) {
            event.preventDefault();
        });
        document.addEventListener('dblclick', function (event) {
            event.preventDefault();
        })
        // /**
        //  * 判断横屏
        //  */
        // window.onorientationchange = () => {
        //     if(window.orientation==90||window.orientation==-90){
        //         this.screen = true
        //     }else{
        //         this.screen = false
        //     }
        //   }
        // 根据环境变量 配置环境参数, 默认是本地开发环境
        // const env = process.env.NODE_ENV;
        // if(env === 'production'){
        //     !function test(){
        //         // 捕获异常，递归次数过多调试工具会抛出异常。
        //         try { !
        //             function cir(i) {
        //                 // 当打开调试工具后，抛出异常，setTimeout执行test无参数，此时i == NaN，("" + i / i).length == 3
        //                 // debugger设置断点
        //                 (1 !== ("" + i / i).length || 0 === i) &&
        //                 function() {}.constructor("debugger")(),
        //                     cir(++i);
        //             } (0)
        //         } catch(e) {
        //             setTimeout(test, 500)
        //         }
        //     }()
        // }
    },
    methods:{
      getUrlQuery(queryName) {
        var reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return r[2];
        return null;
      }
    }
}
</script>
<style lang="less">
html,body,#app {height: 100%; margin: 0; padding: 0; font-size: 14px; font-family: 'Avenir',Helvetica,Arial,sans-serif;}
.screen-orientation{
    position: fixed;
    z-index: 2000;
    left:0px;
    top:0px;
    font-size: .2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
    background: #e1e1e1;
}
</style>
