export default {
    namespaced: true,
    state: {
        clientSide:false, //客户端
        sessionStatus:false, //会话状态
    },
    mutations: {
        /**
         * 客户端状态
         * @param state
         * @param clientSide
         * @constructor
         */
        SET_CLIENT_SIDE (state,clientSide) {
            state.clientSide = clientSide;
        },
        /**
         * 会话状态
         * @param state
         * @param sessionStatus
         * @constructor
         */
        SET_SESSION_STATUS (state,sessionStatus) {
            state.sessionStatus = sessionStatus;
        }
    },
}