import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from "js-cookie";
const Login = () => import('@/views/Login/login.vue');
const Index = () => import('@/views/Index/index.vue');
// const Contacts = () => import('@/views/Contacts/contacts.vue');
// const Invitation = () => import('@/views/Invitation/invitation.vue');
// const UserCenter = () => import('@/views/UserCenter/userCenter.vue');
// const ChatRecord = () => import('@/views/ChatRecord/chatRecord.vue');
const BlankPage = () => import('@/views/BlankPage/blankPage.vue');
const DeviceList = () => import('@/views/Device/List/list.vue');
const DeviceInfo = () => import('@/views/Device/Info/info.vue');
const ProcessConfig = () => import('@/views/Workflow/Process/Config/config.vue');
const TaskInspectionList = () => import('@/views/Workflow/TaskInspection/List/list.vue');
const TaskInspectionInfo = () => import('@/views/Workflow/TaskInspection/Info/info.vue');
const TaskGuidanceList = () => import('@/views/Workflow/TaskGuidance/List/list.vue');
const TaskGuidanceInfo = () => import('@/views/Workflow/TaskGuidance/Info/info.vue');
const RecordInspectionList = () => import('@/views/Workflow/RecordInspection/List/list.vue');
const RecordInspectionInfo = () => import('@/views/Workflow/RecordInspection/Info/info.vue');
const RecordGuidanceList = () => import('@/views/Workflow/RecordGuidance/List/list.vue');
const RecordGuidanceInfo = () => import('@/views/Workflow/RecordGuidance/Info/info.vue');
const Electrical = () => import('@/views/Device/Electrical/electrical.vue');
const EmbedRTC = () => import('@/views/EmbedRTC/index.vue');
Vue.use(VueRouter)
/**
 * 报错显示路由频繁点击导致路由重复而报错，该报错对路由跳转功能没有任何影响。
 * @type {{}}
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/embed/rtc',
        name: 'EmbedRTC',
        component: EmbedRTC,
    },
    {
        path: '/',
        name: 'Index',
        component: Index,
        redirect: { name: "BlankPage" },
        children:[
            {
                path:'',
                name:'BlankPage',
                component:BlankPage
            },
            {
                path: '/device/list',
                name:'DeviceList',
                component: DeviceList,
            },
            {
                path:'/device/info',
                name:'DeviceInfo',
                component:DeviceInfo
            },
            {
                path:'/device/electrical/config',
                name:'Electrical',
                component:Electrical
            },
            {
                path:'/workflow/task/inspection/list',
                name:'TaskInspectionList',
                component:TaskInspectionList
            },
            {
                path:'/workflow/task/inspection/info',
                name:'TaskInspectionInfo',
                component:TaskInspectionInfo
            },
            {
                path:'/workflow/task/guidance/list',
                name:'TaskGuidanceList',
                component:TaskGuidanceList
            },
            {
                path:'/workflow/task/guidance/info',
                name:'TaskGuidanceInfo',
                component:TaskGuidanceInfo
            },
            {
                path:'/workflow/record/inspection/list',
                name:'RecordInspectionList',
                component:RecordInspectionList
            },
            {
                path:'/workflow/record/inspection/info',
                name:'RecordInspectionInfo',
                component:RecordInspectionInfo
            },
            {
                path:'/workflow/record/guidance/list',
                name:'RecordGuidanceList',
                component:RecordGuidanceList
            },
            {
                path:'/workflow/record/guidance/info',
                name:'RecordGuidanceInfo',
                component:RecordGuidanceInfo
            },
            {
                path: '/workflow/config',
                name: 'ProcessConfig',
                component: ProcessConfig,
            },
            // {
            //     path: 'invitation',
            //     name: 'Invitation',
            //     component: Invitation
            // },
            // {
            //     path: '/chatRecord',
            //     name: 'ChatRecord',
            //     component: ChatRecord
            // },
            // {
            //     path: '/userCenter',
            //     name: 'UserCenter',
            //     component: UserCenter
            // }
        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = '灵云AR智能服务平台'
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
            name: from.name
        }) : next('/'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到首页
    } else {
        next()
        const isLogin = Cookies.get('token') //判断是否登录
        if (!isLogin) {
            if (to.name === 'Login') {
                next()
            }else if(to.name === 'EmbedRTC'){
                next()
            }else {
                next('/login')
            }
        } else {
            if (to.name === 'Login') {
                next('/')
            } else {
                next()
            }
        }
    }
});
export default router
